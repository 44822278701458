.Datepicker {
	:global(.react-datepicker-ignore-onclickoutside) {
		outline: 0;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}
	&__popper {
		$_this: &;
		&#{&} {
			padding: 0;
			width: 100%;
		}
	}
	&__calendar {
		font-family: inherit;
		border-radius: 0;
		border-color: var(--nsw-grey-01);
		width: 100%;
		font-size: 0.875rem;
		line-height: 1.36;

		:global([data-placement*='top']) & {
			border-bottom: 0;
			margin-bottom: -3px;
		}
		:global([data-placement*='bottom']) & {
			border-top: 0;
			margin-top: -0.0625rem;
		}

		:global(.react-datepicker__header) {
			background-color: transparent;
			padding-top: 0;
			padding-bottom: 0;
		}

		:global(.react-datepicker__day-names),
		:global(.react-datepicker__week) {
			display: grid;
			grid-template-columns: repeat(7, 1fr);
		}

		:global(.react-datepicker__day-names) {
			background-color: var(--nsw-grey-01);
			margin-bottom: 0;
		}

		:global(.react-datepicker__day-name) {
			color: #fff;
			text-transform: uppercase;
		}
		:global(.react-datepicker__triangle) {
			&:before,
			&:after {
				content: none !important;
			}
		}
		:global(.react-datepicker__day-name),
		:global(.react-datepicker__day),
		:global(.react-datepicker__time-name) {
			border-radius: 0;
			margin: 0;
			padding: 0.375rem;
			width: auto;
		}

		:global(.react-datepicker__day--in-range),
		:global(.react-datepicker__day--in-selecting-range) {
			background: rgba(0, 38, 100, 0.1);
			color: inherit;
		}
		:global(.react-datepicker__month) {
			margin: 0;
		}
		:global(.react-datepicker__month-container) {
			float: none;
		}

		:global(.react-datepicker__current-month),
		:global(.react-datepicker-time__header),
		:global(.react-datepicker-year-header) {
			font-size: inherit;
			line-height: inherit;
		}

		:global(.react-datepicker__current-month) {
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
		:global(.react-datepicker__navigation) {
			width: 1.5rem;
			height: 1.5rem;
			top: 8px;
		}
		:global(.react-datepicker__navigation--previous) {
			left: 0.5rem;
		}
		:global(.react-datepicker__navigation--next) {
			right: 0.5rem;
		}
		:global(.react-datepicker__navigation-icon:before) {
			border-color: var(--nsw-text-dark);
			width: 8px;
			height: 8px;
			border-width: 2px 2px 0 0;
		}
	}
}
