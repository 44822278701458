.search-bar {
  border: solid 1px $light40;
  border-radius: 5px;
  width: 100%;
  padding: 7px 0px;

  &.MuiInputBase-root {
    flex: 1;
  }
  .MuiInputBase-inputTypeSearch {
    appearance: none;
    -webkit-appearance:none;
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -webkit-appearance:none;
    }
  }
}
