@import './legacy-ported/sass/abstracts/mixins';

.nav-page {
	display: flex;
	flex-flow: column;
	min-height: 100vh;

	&__main-header {
		@include all-mobile-devices {
			display: none;
		}
	}

	&__mobile-header {
		display: none;

		@include all-mobile-devices {
			display: flex;
		}
	}

	&__content {
		// margin-top: 20px;
		width: 100%;
	}

	&__scrollToTop {
		z-index: 999;
		color: white;
		padding: 10px;
		width: 54px;
		height: 54px;
		min-width: 0;
		box-shadow: none;
		background: rgba(34, 39, 43, 0.8);
		border-radius: 0;
		position: fixed;
		right: 1.5rem;
		bottom: 0;
		margin-bottom: 1.5rem;
		border-radius: 50%;
		@include all-mobile-devices {
			right: 5%;
		}
	}
}
