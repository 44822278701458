.user-select-card {
    background: #F4F4F7 !important;
    border-radius: 10px !important;
    width: 100%;
    cursor: pointer;

    &__image {

        img {
            width: 100%;
        }
    }

    &__title-description {
        padding: 1rem 1.5rem;
    }

    &__title {
        width: 100%;
        color: $blue-primary;
        font-size: 24px;
        line-height: 32px;
    }

    &__description {
        width: 100%;
    }

    &__icon {
        padding: 10px 0px 0px;
    }
}