.component-with-page-nav-links {
	hr {
		margin-top: 20px;
		width: 100%;
		color: rgba(0, 0, 0, 0.5);
	}

	&__buttons {
		display: flex;
		margin: 30px 0;

		.anchor {
			a {
				width: 100%;
				height: 100%;
			}

			display: flex;
			border: 1px solid #a0a5ae;
			border-left: 2px solid $red-primary;
			box-sizing: border-box;
			padding: 15px 20px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			text-decoration: none !important;
			margin-right: 15px;
			color: #333333 !important;

			@include all-mobile-devices {
				flex-direction: column;
				justify-content: space-between;
				margin-bottom: 15px;
			}
		}

		@include all-mobile-devices {
			flex-direction: column;
			justify-content: space-between;
		}
	}

	&__content-wrapper {
		padding-top: 15px;

		h3,
		h4,
		h5 {
			padding: 10px 0;
		}
	}

	&__content {
		padding: 15px 0;
		width: 100%;
	}

	&__content-title {
		padding: 10px 0;
	}

	&__content-body {
	}

	img {
		@include all-mobile-devices {
			max-width: 100%;
		}
	}
}
