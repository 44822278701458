@use "./legacy-ported/sass/abstracts/variables";

// /* cyrillic-ext */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../../assets/fonts/Montserrat-Medium.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }
// /* cyrillic-ext */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   src: url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
//   unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
// }
// /* cyrillic */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   src: url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
//   unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
// }
// /* vietnamese */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   src: url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
//   unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
// }
// /* latin-ext */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   src: url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
//   unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
// }
// /* latin */
// @font-face {
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: 600;
//   src: url('../../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
//   unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
// }

html,
body {
  font-family: "Public Sans", Arial, sans-serif;
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

h1, .h1 {
  font-size: 48px;
  font-weight: 600;
  line-height: 60px;

  @media (max-width: 991px) {
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
  }
}

h2, .h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;

  @media (max-width: 991px) {
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
  }
}

h3, .h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;

  @media (max-width: 991px) {
    font-size: 22px;
    font-weight: 600;
    line-height: 27.5px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;

  @media (max-width: 991px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}

h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;

  @media (max-width: 991px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
}

h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 991px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
}

p {
//   font-size: 16px;
//   line-height: 24px;
}

.intro-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  @media (max-width: 991px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
}

.small-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;

  @media (max-width: 991px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}

a {
  color: variables.$black;
  text-decoration: underline;
}

.bold{
  font-weight: 600;
}
