.page-not-found{
  &__message{
    max-width: 540px;
    margin-bottom: 1.5em;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  &__back-button-label {
    margin-left: 10px;
  }
}
