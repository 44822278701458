@use "./legacy-ported/sass/abstracts/mixins";

.site-footer {
  color: white;
  background-color: #333333;
  padding: 32px 0;

  a{
    font-weight: 600;
  }

  @include mixins.mobile {
    padding: 16px 0;
  }
  @include mixins.mobile-large {
    padding: 16px 0;
  }

  &__acknowledgement {
    margin: 0;
    padding-bottom: 16px;
    font-size: 14px;

    border-bottom: solid 1px #A0A5AE;
  }

  &__link-list {
    margin: 16px 0;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    @include mixins.mobile {
      display: grid;
      gap: 16px;
      grid-template: repeat(3, auto) / auto;
      grid-auto-flow: column;
      justify-content: space-between;
    }
    @include mixins.mobile-large {
      display: grid;
      gap: 16px;
      grid-template: repeat(3, auto) / auto;
      grid-auto-flow: column;
      justify-content: space-between;
    }
  }

  &__link {
    margin-right: 16px;
    font-size: 12px;
    color: white;

    &:last-child {
      margin-right: 0;
    }

    @include mixins.mobile {
      margin-right: 0;
    }
    @include mixins.mobile-large {
      margin-right: 0;
    }
  }

  &__copyright {
    a{
      color: white;
      font-weight: 400;
    }
  }
}
