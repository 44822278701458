.html-content {
  h2,
  h4,
  h4,
  h5,
  h6,
  p {
    margin: 10px 0px;
  }

  ul,
  ol {
    padding-left: 30px;
  }
}
