.DownloadList {
	:global {
		.Mui-disabled {
			&.MuiCheckbox-root {
				svg rect{
					&:nth-child(2) {
						stroke: #F2F2F2
					}
				}
			}
		}
	}
}