@import "../../abstracts/mixins";

.syllabus-group {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  max-width: 900px;
  width: 100%;

  &__heading {
    margin-bottom: 35px;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;

    h2 {
      font-size: 20px;
      line-height: 28px;
    }
  }

  &__card-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;

    margin-bottom: 76px;
  }

  &__card {
    width: calc(25% - 15px);
    margin: 0px 20px 25px 0px;

    @include mobile {
      width: 100%;
      margin: 0px 0px 25px 0px;

    }
    @include mobile-large {
      width: calc(50% - 10px);
      margin: 0px 0px 25px 0px;
    }

  }

  &__card:first-child {
    //margin: 0px 0px 25px 0px;
  }
}
