.credentials-home {
  &__description {
    margin-bottom: 1rem;
  }

  &__updates-card {
    padding: 25px;
    margin: 25px 0px;
  }

  &__update-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 3px 0px;

    .datebox {
      width: 150px;
      justify-content: center;
    }
  }

  &__year-update {
    padding: 10px 0px;
    border-bottom: 2px solid $light40;
  }

  &__year-update:last-child {
    border-bottom: none;
  }

  &__year {
    display: flex;
    justify-content: flex-end;
  }
}
