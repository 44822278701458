*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	box-sizing: border-box;
	background-color: white;
	max-height: 100%;
	max-width: 100vw;
}

#app {
	width: 100%;
	height: 100%;
	max-width: 100vw;
	min-height: 100vh;
}

.strong {
	font-weight: bold;
}

button {
	font-family: inherit;
}

// Shows an icon after links that open in a new tab
// Except for those marked 'no-icon' or those for email addresses
a[target='_blank']:not(.no-icon, [href^='mailto'], [data-asset-id])::after {
	font-family: icomoon;
	content: '\e900';
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	fill: currentColor;

	margin-left: 5px;
}

hr {
	border-top: 1px solid $light20;
}

svg {
	display: initial;
}

// input {
// 	&[type="text"],
// 	&[type="email"],
// 	&[type="search"] {
// 		border: 2px inset -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
// 	}
// }
