$white: #ffffff;
$black: #000000;
$green: #3ee1aa;

$blue-primary: #002664;
$red-primary: #D7153A;
$blue-secondary: #2E5299;
$blue-tertiary: #0085B3;
$grey: #F4F4F7;
$border-color: #a0a5ae;
$white: #FFFFFF;
$light10: #F4F4F7;
$light20: #E4E4E6;
$light40: #A0A5AE;
$dark60: #6D7079;
$dark70: #4C4F55;
$dark70: #878787;
$dark80: #333333;
$black: #000000;
$notification_info_blue: #2E5299;
$notification_success_green: #00A908;
$notification_warning_orange: #DC5800;
$notification_error_red: #B81237;

$title-font-size: 32px;
$body-font-size: 16px;

$grey-selected: #e0e0e9;
