@import "./legacy-ported/sass/abstracts/mixins";

.syllabus-overview {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: 20px;
  width: 100%;

  @include all-mobile-devices {
    align-items: flex-start;
  }

  &__group-heading--multiple {
    display: flex;
    flex-flow: row;
    &>* {
      margin-right: 50px;
    }

    &>*:last-child {
      margin-right: 0;
    }
  }

  &__group-heading-class {

    @include mobile {
      display: block;
    }

    @include mobile-large {
      display: block;
    }
  }

  &__title {
    margin: 0 0 15px 0;
  }

  &__description {
    margin-bottom: 35px;
  }

  .nsw-form-select{
    @include low-res-tablet-and-mobile-landscape{
      font-size: 16px !important;
      padding: 0.75rem 0.65rem !important;
    }
  }

  .MuiGrid-grid-sm-3 {
    @include low-res-tablet-and-mobile-landscape{
      padding: 4px !important;
    }
  }

  &__teaching-generic-content-page {

    ul, ol {
      padding-left: 35px;
    }
  }

}
