@use './legacy-ported/sass/abstracts/variables';
@use './legacy-ported/sass/abstracts/mixins';

.syllabus-picker {
	// display: grid;
	// grid-template: repeat(2, calc(calc(100% - 5px) / 2)) / 30% 30% 1fr;
	// grid-auto-flow: column;

	@include mixins.all-mobile-devices {
		display: flex;
		flex-direction: column;
	}

	&__picker {
		flex: 1 1 100%;

		// padding: 23px;
		// border: solid 1px variables.$dark80;
		// border-radius: 5px;

		display: flex;
		flex-flow: column nowrap;

		@include mixins.all-mobile-devices {
			border: 0;
			padding: 0;
			overflow-y: scroll;
		}

		&--tall {
			grid-row-end: span 2;
			@include mixins.all-mobile-devices {
				grid-row-end: span 1;
			}
		}

		.tree-picker {
			flex: 1 1;
		}

		&--elements {
			// margin-top: 8px;
		}
	}

	.syllabus-picker__search-bar {
		border: solid 1px variables.$light40;
		border-radius: 5px;
		flex: 0 0;
		min-height: 45px;
		margin-bottom: 10px;
	}

	&__column {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}
