.docx-help {
  padding: 10px;

  &_message {
    margin-bottom: 10px;
  }

  &_step {
    margin-left: 30px;
  }

  &_editing-img {
    margin-left: -15px;
    margin-bottom: 10px;
  }
}
