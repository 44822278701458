.navbar {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-size: 14px;
  margin-bottom: 15px;

  a {
    text-decoration: none !important;
  }

  &__logo {
    //height: 75px;
    //width: 75px;
    margin: 10px 20px 10px 10px;
  }

  &__title-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    font-size: 14px;
    margin: 10px 0;
  }

  &__title {
    margin: 0;
    font-size: 32px;
    line-height: 40px;
  }

  &__sub-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
  }

  &__search {
    flex: 1;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-end;

    border: none;
    background: transparent;
    margin-right: 20px;

    &-icon {
      margin-right: 10px;
    }

    &-text {
      margin-left: 10px;
      font-weight: 600;
    }
  }

  .navbar__search-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 1;
    padding: 0 50px;
    height: 55px;
    margin-bottom: 10px;
  }

  &__menu-container {
    pointer-events: none;

    .navbar-menu {
      pointer-events: auto;
    }
  }
}

.nav-links {
  height: 35px;
  display: flex;
  flex-flow: row;
  align-items: stretch;

  margin-left: 10px;

  &__link {
    display: flex;
    flex-flow: row;
    align-items: center;

    border-right: 1px solid $dark70;
    padding: 0 20px !important;
    text-decoration: none;

    font-weight: 400;
    line-height: 24px;
    font-size: 14px;

    &.active {
      font-weight: 600;
    }

    &:last-child {
      border-right: 0;
    }

    &-icon {
      margin-left: 10px;
    //   margin-top: 5px;
    }
  }
}

// overriding .nsw-page-nav__link
.nsw-page-nav__link {
  cursor: pointer;
}


