.link-and-pdf-card {
  flex: 1;
  padding: 20px;
  margin: 10px 0px;

  &__links-content {
    border-top: 2px solid $light40;
    margin-top: 20px;
    padding: 10px 0px 20px;
  }
}
