@use "./legacy-ported/sass/abstracts/variables";

.syllabus-card {
  display: flex;
  justify-content: stretch;
  max-width: 195px;

  @include mobile {
      max-width: 100%;
  }

  @include mobile-large {
    max-width: 100%;
  }

  .syllabus-card__content {
    padding: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    justify-content: space-between;
    height: 100%;
  }
  &__content-wrapper{
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    gap: 10px;
    height: 70%;
  }

  &--primary {
    border-top: solid 6px variables.$red-primary;
  }
  &--secondary {
    border-top: solid 6px variables.$blue-tertiary;
  }

  &__headline {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &__body {

  }

  &__year {
    font-weight: 600;
  }

  &__link {
    font-weight: 600;
  }
}

.section-card-disabled {
  opacity: 0.5;
  display: flex;
  flex-direction: column;
}
